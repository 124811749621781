<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            <template v-if="userID">
                {{ sprintf($t('pages.ecommerce.user.editUserPattern'), [user.full_name ?? '']) }}
            </template>
            <template v-else>{{ $t('pages.ecommerce.user.newUser') }}</template>
        </h2>

        <router-link v-if="!$root.filterWithUrl" to="/ecommerce/user" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.ecommerce.user.title") }}
        </router-link>
    </div>

    <el-tabs v-model="tabModel" class="user-tabs">
        <el-tab-pane :label="$t('pages.ecommerce.user.save.tabs.generalDetails')" name="general">
            <el-form :model="form.data" ref="userForm">
                <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="row">
                                    <div class="col-md-6 fv-row mb-1">
                                        <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.firstname') }}</label>
                                        <el-form-item prop="firstname" :rules="$validation.getMessage(['required'])">
                                            <el-input v-model="form.data.firstname" type="text"/>
                                        </el-form-item>
                                    </div>
                                    <div class="col-md-6 fv-row mb-1">
                                        <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.lastname') }}</label>
                                        <el-form-item prop="lastname" :rules="$validation.getMessage(['required'])">
                                            <el-input v-model="form.data.lastname" type="text"/>
                                        </el-form-item>
                                    </div>
                                    <div class="col-md-6 fv-row mb-1">
                                        <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.email') }}</label>
                                        <el-form-item prop="email" :rules="$validation.getMessage(['requiredBlur', 'email'])">
                                            <el-input v-model="form.data.email" autocomplete="off"/>
                                        </el-form-item>
                                    </div>
                                    <div class="col-md-6 fv-row mb-1">
                                        <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.gender') }}</label>
                                        <el-form-item prop="gender_id">
                                            <el-select v-model="form.data.gender_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                                <el-option v-for="(gender, genderIndex) in genders" :key="genderIndex" :value="gender.id" :label="gender.name">{{ gender.name }}</el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <div class="col-md-6 fv-row mb-1">
                                        <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.identificationNumber') }}</label>
                                        <el-form-item prop="identification_number">
                                            <el-input v-model="form.data.identification_number" v-mask="{ mask:'99999999999', placeholder: '' }" type="text"/>
                                        </el-form-item>
                                    </div>
                                    <div class="col-md-6 fv-row mb-1">
                                        <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.birthDate') }}</label>
                                        <el-form-item prop="birth_date">
                                            <el-date-picker v-model="form.data.birth_date" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledDate"></el-date-picker>
                                        </el-form-item>
                                    </div>
                                    <template v-if="!form.updateStatus">
                                        <div class="col-md-6 fv-row mb-1">
                                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.password') }}</label>
                                            <el-form-item prop="password" :rules="rules.password">
                                                <el-input v-model="form.data.password" autocomplete="off" type="password"/>
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-6 fv-row mb-1">
                                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.passwordConfirmation') }}</label>
                                            <el-form-item prop="password_confirmation" :rules="rules.password_confirmation">
                                                <el-input v-model="form.data.password_confirmation" autocomplete="off" type="password"/>
                                            </el-form-item>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.mobilePhone') }}</label>
                                    <el-form-item prop="mobile_phone">
                                        <el-input v-model="form.data.mobile_phone" v-mask="{ mask:'+\\90 599 999 99 99', placeholder: '' }" type="text"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.isAbroad') }}</label>
                                    <el-form-item prop="status">
                                        <el-radio-group v-model="form.data.is_abroad">
                                            <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.status') }}</label>
                                    <el-form-item prop="status">
                                        <el-radio-group v-model="form.data.active">
                                            <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mt-5">
                                    <el-form-item class="mb-0">
                                        <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                            <span v-if="form.loading" class="indicator-progress">
                                                {{ $t("messages.wait") }}
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
            <div class="change-password-box mt-12 mt-md-n20" v-if="form.updateStatus">
                <el-form :model="form.data" ref="changePasswordForm">
                    <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
                        <div class="col-md-8 mt-0">
                            <div class="card pt-4 mb-6 mb-xl-9" >
                                <div class="card-header">
                                    <h2 class="card-title fw-bolder">{{ $t("pages.ecommerce.user.changePassword") }}</h2>
                                </div>
                                <div class="card-body pt-5 pb-5">
                                    <div class="row">
                                        <div class="col-md-6 fv-row mb-1">
                                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.password') }}</label>
                                            <el-form-item prop="password" :rules="rules.password">
                                                <el-input v-model="form.data.password" autocomplete="off" type="password"/>
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-6 fv-row mb-1">
                                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.passwordConfirmation') }}</label>
                                            <el-form-item prop="password_confirmation" :rules="rules.password_confirmation">
                                                <el-input v-model="form.data.password_confirmation" autocomplete="off" type="password"/>
                                            </el-form-item>
                                        </div>
                                    </div>
                                    <div class="fv-row">
                                        <button @click.prevent="onChangePasswordSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="button">
                                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.change") }}</span>
                                            <span v-if="form.loading" class="indicator-progress">
                                                {{ $t("messages.wait") }}
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-form>
            </div>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus" :label="$t('pages.ecommerce.user.save.tabs.addressSettings')" name="addressSettings">
            <UserAddress :userID="userID" v-if="tabModel == 'addressSettings'"></UserAddress>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus" :label="$t('pages.ecommerce.user.save.tabs.orderSettings')" name="orderSettings">
            <Order :userID="userID" v-if="tabModel == 'orderSettings'"></Order>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus" :label="$t('pages.ecommerce.user.save.tabs.statistic')" name="statistic">
            <StatisticStudent :users="[userID]" v-if="tabModel == 'statistic'" :showUserTitle="false"></StatisticStudent>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus" :label="$t('pages.ecommerce.user.save.tabs.historyLogin')" name="historyLogin">
            <UserHistoryLogin :userID="userID" v-if="tabModel == 'historyLogin'"></UserHistoryLogin>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import UserAddress from "@/components/ecommerce/user/address";
import UserHistoryLogin from "@/components/ecommerce/user/history/login";
import Order from "@/components/ecommerce/order";
import StatisticStudent from "@/components/statistic/student";

export default {
    name: "_id",
    components: {
        UserAddress,
        Order,
        StatisticStudent,
        UserHistoryLogin
    },
    data(){
        return {
            tabModel: 'general',
            user: {},
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {
                    password: '',
                    password_confirmation: '',
                    is_abroad: false,
                    active: true
                },
            },
            rules: {}
        }
    },
    computed: {
        userID() {
            return this.$route.params.id;
        },
        genders() {
            return this.$store.state.ecommerce.user.gender.table.data;
        },
    },
    created() {
        if (this.userID && !(this.userID > 0)) {
            this.$router.push({
                path: "/ecommerce/user"
            });
        }

        this.rules = {
            password: [{validator: this.validatePass, trigger: ['blur']}],
            password_confirmation: [{validator: this.validatePass2, trigger: ['blur', 'change']}],
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("menu.users"), [this.$t("menu.ecommerceManagement"), this.$t("menu.userManagement")]);

        if (this.userID && this.userID > 0) {
            this.loadUser();
        }

        this.$store.dispatch('ecommerce/user/gender/get', {
            page: {
                pageSize: 9999
            }
        });
    },
    methods: {
        loadUser(userID = this.userID) {
            this.axios.get(this.endpoints['user'] + '/' + userID).then((response) => {
                let data = response.data.data;
                this.user = data;

                this.form.updateStatus = true;
                this.form.data = Object.assign({
                    password: '',
                    password_confirmation: '',
                }, data)
            });
        },
        onSubmit() {
            this.$refs.userForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['user'] + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadUser();
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        this.axios.post(this.endpoints['user'], this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                let userID = response.data.data.id;
                                this.$router.push({
                                    path: "/ecommerce/user/save/" + userID
                                });

                                this.loadUser(userID);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        onChangePasswordSubmit() {
            this.$refs.changePasswordForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    this.axios.put(this.endpoints['user_change_password'] + '/' + this.userID, this.form.data).then(response => {
                        this.onResponse(response.data, () => {}, () => {
                            this.form.loading = false;
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data, () => {
                            this.form.loading = false;
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        validatePass(rule, value, callback) {
            if (value === '') {
                callback(new Error(this.$t('validation.password')))
            } else {
                if (this.form.data.password_confirmation !== '') {
                    let form = this.form.updateStatus ? 'changePasswordForm' : 'userForm';
                    this.$refs[form].validateField('password_confirmation')
                }
                callback()
            }
        },
        validatePass2(rule, value, callback) {
            if (value === '') {
                callback(new Error(this.$t('validation.passwordConfirmation')))
            } else if (value !== this.form.data.password) {
                callback(new Error(this.$t('validation.dontMatchPassword')))
            } else {
                callback()
            }
        },
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
</style>