<template>
    <custom-table
        :title="$t('pages.ecommerce.user.history.login.title')"
        :subTitle="$t('pages.ecommerce.user.history.login.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @changeTable="handleTableChange">
        <template v-slot:created_at="{ row: record }">
            {{ $moment(record.created_at).format("YYYY-MM-DD HH:mm") }}
        </template>
    </custom-table>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    props: ['userID'],
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("common.ipAddress"),
                    key: "ip"
                },
                {
                    name: this.$t("common.userAgent"),
                    key: "user_agent"
                },
                {
                    name: this.$t("common.createdAt"),
                    scopedSlots: {customRender: "created_at"},
                },
            ],
            actions: [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
        }
    },
    computed: {
        table() {
            return this.$store.state.ecommerce.user.history.login.table;
        },
    },
    mounted() {
        this.$store.dispatch('ecommerce/user/history/login/get', {
            page: {},
            filterData: {
                user_id: this.userID,
                sort: 'id:desc'
            }
        });
    },
    methods:{
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("ecommerce/user/history/login/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("ecommerce/user/history/login/get", {
                page: pagination,
                filterData: Object.assign({
                    sort: 'id:desc',
                    user_id: this.userID
                }, filterData)
            });
        },
    }
}
</script>

<style scoped>

</style>